export function generateBriefing(params) {
  return window.$axios.get(`${window.service.api}/message/briefing`, { params })
}

export function shortcut(params) {
  return window.$axios.get(`${window.service.api}/message/shortcut`, {params: params})
}

export function lists(params) {
  return window.$axios.get(`${window.service.api}/message/lists`, { params })
}

export function index(params) {
  return window.$axios.get(`${window.service.api}/message/index`, {params: params})
}

export function eventSequenceData(params) {
  return window.$axios.get(`${window.service.api}/message/event_sequence`, { params })
}

export function eventBriefing(params) {
  return window.$axios.get(`${window.service.api}/message/event_briefing`, {params: params})
}

// 信息列表 事件分析
export function eventParse(params) {
  return window.$axios.get(`${window.service.api}/message/event_parse`, { params })
}

// 文档标记
export function docMark(data) {
  return window.$axios.put(`${window.service.api}/doc/mark`, data)
}

// 取消文档标记
export function cancelDocMark(params) {
  return window.$axios.delete(`${window.service.api}/doc/mark`, { params })
}

// 被标记文档列表
export function markList(params) {
  return window.$axios.get(`${window.service.api}/doc/marks`, { params })
}

// 文档纠错
export function docFeedback(data) {
  return window.$axios.put(`${window.service.api}/doc/feedback`, data)
}

// 文档情感标记
export function markEmotion(data) {
  return window.$axios.put(`${window.service.api}/doc/markEmotion`, data)
}

// 信息列表媒体类型统计
export function docCount(params) {
  return window.$axios.get(`${window.service.api}/message/docStatOrigin`, { params })
}

// 生成简报下一步检查重复
export function checkForm(params) {
  return window.$axios.get(`${window.service.api}/briefing/checkForm`, { params })
}