<template>
  <el-card class="brief-demo">
    <dl>
      <dt>简报模版</dt>
      <dd>
        <a class="jdtj" href="/brief/example/classic" target="_blank">经典统计模版</a>
      </dd>
      <dd>
        <a href="/brief/example/advanced" target="_blank" class="zdxx">重点信息模版</a>
      </dd>
      <dd>
        <a href="/brief/example/common" target="_blank" class="rbcy">日报常用模版</a>
      </dd>
      <dd>
        <a href="/brief/example/mix" target="_blank" class="rbhh">日报混合模版</a>
      </dd>
      <dd>
        <a href="/brief/example/abstract" target="_blank" class="xxzy">信息摘要模板</a>
      </dd>
    </dl>
    <dl class="custom-brief mar-t-20">
      <dt>自定义简报</dt>
      <dd @click="toCustom">
        <a class="add">新建自定义模版</a>
      </dd>
      <dd class="oparete-brief" v-for="(row, index) in list" :key="index">
        <a class="template">{{ row.name }}</a>
        <div class="operate">
          <i class="iconfont icon-bianji" @click="toCustom({ id: row.id })" />
          <i class="iconfont icon-shanchu" @click="delBriefConfirm(row)" />
        </div>
      </dd>
    </dl>
    <confirm
      ref="confirmDel"
      confirm="确定"
      title="删除"
      class="delet-brief single-information"
      @must="dropTemplate" />
  </el-card>
</template>
<script>
import confirm from '@components/common/confirm'
import { lists, drop } from '@/api/custom_briefing'
export default {
  name: 'component-stencil',
  components: {
    confirm
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.fetchTemplate()
  },
  methods: {
    toCustom(params = {}) {
      this.$router.push({name: 'custom-brief', query: params})
    },
    fetchTemplate() {
      lists().then(res => {
        if (res.data.state) {
          const { data } = res.data
          this.list = data
        }
      }).catch(e => {
        window.console.error(e)
      })
    },
    delBriefConfirm(template) {
      const textBox = [
        {
          type: 'error',
          text: `确认要删除自定义模板“${template.name}”吗？`
        }
      ]
      this.$refs.confirmDel.show(textBox, { id: template.id })
    },
    dropTemplate(data) {
      drop(data).then(res => {
        if (res.data.state) {
          this.$message.success('删除成功')
          this.fetchTemplate()
        } else {
          this.$message.error('删除失败')
        }
      }).catch(e => {
        window.console.error(e)
      })
    }
  }
}
</script>
<style scoped>
.brief-demo {
  margin: 20px 20px 20px 10px;
  padding: 0 40px;
  box-sizing: border-box;
  height: calc(100% - 40px);
}
.brief-demo dl {
  width: 100%;
  overflow: hidden;
}
.brief-demo dt {
  font-size: 14px;
  font-weight: 550;
  line-height: 50px;
  width: 100%;
  margin-bottom: 10px;
}
.brief-demo dl dd {
  float: left;
  margin-right: 30px;
  display: inline;
  cursor: pointer;
}
.brief-demo dl a {
  width: 120px;
  height: 158px;
  display: block;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
/*简报绘制——模版管理*/
.brief-demo .jdtj {
  background-image: url(../../assets/images/brief/jdtj_mb.png);
}
.brief-demo .zdxx {
  background-image: url(../../assets/images/brief/zdxx_mb.png);
}
.brief-demo .rbcy {
  background-image: url(../../assets/images/brief/rbcy_mb.png);
}
.brief-demo .rbhh {
  background-image: url(../../assets/images/brief/rbhh_mb.png);
}
.brief-demo .xxzy {
  background-image: url(../../assets/images/autobrief/xxzy_mb.png);
}
/* 自定义简报 */
.custom-brief dd .add,
.custom-brief dd .template {
  text-align: center;
  font-size: 14px;
  font-weight: 550;
  text-indent: 1px;
}
.custom-brief .add {
  background-image: url(../../assets/images/brief/add.png);
  line-height: 200px;
}
.custom-brief .template {
  background-image: url(../../assets/images/brief/template.png);
  line-height: 100px;
}
.custom-brief .oparete-brief {
  position: relative;
}
.custom-brief dd .operate {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 98%;
  height: 98%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  display: none;
}
.custom-brief .oparete-brief:hover .operate {
  display: block;
}
.custom-brief .operate .iconfont {
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  margin-top: 120px;
  display: inline-block;
}
.custom-brief .operate .icon-bianji {
  margin-left: 25px;
  margin-right: 35px;
}
.delet-brief .cont {
  font-size: 14px;
}
.delet-brief .Dialog_style1 li img {
  display: none;
}
</style>
