<template>
  <div class="plan-brief flex">
    <el-card class="mar-t-20">
      <el-steps :active="step" finish-status="success" align-center>
        <el-step title="基本信息" description="编辑简报的基本信息"></el-step>
        <el-step title="选择模版" description="选择简报生成后的样式"></el-step>
        <el-step title="高级筛选" description="精准筛选简报数据"></el-step>
        <el-step title="完成" description="完成简报制作"></el-step>
      </el-steps>
    </el-card>
    <el-card class="plan-brief-cont mar-t-20 flex-1">
      <!-- 基本信息 -->
      <div v-show="step === 1">
        <el-form ref="where" :rules="rules.where" :model="where" label-width="80px">
          <el-form-item label="生成方案" prop="plan_id">
            <el-select v-model="where.plan_id" placeholder="请选择方案" class="w-200">
              <el-option :label="plan.name" :value="plan.id" v-for="(plan, index) in plans" :key="index"  />
            </el-select>
          </el-form-item>
          <el-form-item label="生成时间" prop="span">
            <el-date-picker
                    class="w-350"
                    v-model="where.span"
                    @change="dateSpanChange"
                    :clearable="false"
                    :picker-options="{disabledDate: dateRange, shortcuts: pickerOptions.shortcuts}"
                    :editable="false"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']" />
          </el-form-item>
          <el-form-item label="简报名称" prop="name">
            <el-input
              v-model="where.name"
              class="w-200"
              placeholder="请输入报告名称"
              maxlength="10"
              show-word-limit />
          </el-form-item>
          <el-form-item label="副标题" prop="title">
            <el-input
              v-model="where.title"
              class="w-350"
              placeholder="（非必填）请输入副标题"
              maxlength="30"
              show-word-limit />
          </el-form-item>
          <el-form-item>
            <div class="button-group">
              <el-button type="primary" round @click="prevStep">上一步</el-button>
              <el-button type="primary" round @click="nextStep('where', true)">下一步</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!--@end 基本信息 -->
      <!-- 选择模版 -->
      <div v-show="step === 2" class="select-template">
        <el-form class="flex space-between">
          <el-form-item label="主题颜色">
            <div
              v-for="item in themeList"
              :key="item.label"
              class="theme_colors">
              <el-radio :label="item.label" v-model="where.theme_color">{{ item.name }}</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="模版选择" required>
            <el-radio-group v-model="template">
              <el-radio label="sys">系统模版
                <el-select
                  v-show="template === 'sys'"
                  v-model="where.type"
                  placeholder="请选择"
                  class="w-200 mar-l-10">
                  <el-option label="经典统计模版" value="classic" />
                  <el-option label="重点信息模版" value="enhance" />
                  <el-option :disabled="!showDaily" label="日报常用模版" value="daily_common" />
                  <el-option :disabled="!showDaily" label="日报混合模版" value="daily_mix" />
                  <el-option label="信息摘要模板" value="abstract" />
                </el-select>
              </el-radio>
              <el-radio label="custom">自定义模版
                <el-select no-data-text="暂无模板"
                  v-show="template === 'custom'"
                  v-model="where.custom_template_id"
                  placeholder="请选择"
                  class="w-200 mar-l-10">
                  <el-option :label="template.name" :value="template.id" v-for="(template, index) in templateList" :key="index" />
                </el-select>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-card class="system-template">
          <img src="@/assets/images/common/top_banner_blue.png" class="banner" v-show="where.theme_color === 'blue'" />
          <img src="@/assets/images/common/top_banner_red.png" class="banner" v-show="where.theme_color === 'red'" />
          <img src="@/assets/images/common/top_banner_black.png" class="banner" v-show="where.theme_color === 'black'" />
          <h3 class="title">{{ where.name }}</h3>
          <h4 class="sub-title">
            {{ where.title }}
          </h4>
          <p class="summary">
            <i class="iconfont icon-select-brief mar-r-10" />请选择您要使用的模版～
          </p>
        </el-card>
        <div class="button-group mar-t-45">
          <el-button type="primary" round @click="prevStep">上一步</el-button>
          <el-button type="primary" round @click="nextStep('where')" :disabled="disabledNext">下一步</el-button>
        </div>
      </div>
      <!--@end 选择模版 -->
      <!-- 高级筛选 -->
      <div v-show="step === 3" class="senior-filter">

        <div v-show="where.type === 'daily_mix' && showDaily">
          <el-button class="gjsz_btn" plain size="mini" @click="setting.open = !setting.open">高级设置</el-button>
        </div>
        <div class="gjsz_cont" v-show="setting.open && where.type === 'daily_mix' && showDaily">
          <el-form label-width="70px">
            <el-form-item label="维度分析">
              <div class="wdfx_cont">
                <div class="item flex" v-for="(dimension, index) in setting.dimensions" :key="index">
                  <el-input v-model="dimension.name" type="text" maxlength="6" show-word-limit class="w-120 mar-r-10" />
                  <div class="flex-1">
                    <div class="ov">
                      <el-input
                              type="textarea"
                              :id="`plan_brief_dimension${index}`"
                              :autosize="{minRows:1}"
                              v-model="dimension.exps"
                              class="textarea plan-primary f-l"
                              placeholder='关键词请用"+"、"-"、"("、")"隔开' />
                      <i class="iconfont icon-guanbi f-l" @click="removeItem('dimension', index)" v-show="setting.dimensions.length > 1" />
                    </div>
                    <div class="fh_btn_group mar-t-5">
                      <el-button @click="virtualEnter('+', `plan_brief_dimension${index}`, dimension)">＋</el-button>
                      <el-button @click="virtualEnter('|', `plan_brief_dimension${index}`, dimension)">|</el-button>
                      <el-button @click="virtualEnter('-', `plan_brief_dimension${index}`, dimension)">－</el-button>
                      <el-button @click="virtualEnter('(', `plan_brief_dimension${index}`, dimension)">(</el-button>
                      <el-button @click="virtualEnter(')', `plan_brief_dimension${index}`, dimension)">)</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
              <el-button plain class="bd_n circle-plus" icon="iconfont icon-zengjia" @click="addItem('dimension')">新增维度</el-button>
            </el-form-item>
            <el-form-item label="竞品分析" class="jpfx mar-t-10">
              <div class="wdfx_cont">
                <div class="item" v-for="(id, index) in setting.plan_ids" :key="index">
                  <el-select v-model="setting.plan_ids[index]" placeholder="请选择竞品方案">
                    <el-option :disabled="setting.plan_ids.indexOf(plan.pid) !== -1" :label="plan.name" :value="plan.pid" v-for="(plan, index) in plans" :key="index" />
                  </el-select>
                  <i class="iconfont icon-guanbi" @click="removeItem('plan', index)" v-show="setting.plan_ids.length > 1" />
                </div>
              </div>
              <el-button plain class="bd_n circle-plus" icon="iconfont icon-zengjia" @click="addItem('plan')">新增竞品方案</el-button>
            </el-form-item>
          </el-form>
        </div>



        <el-form label-width="120px" ref="form" :rules="rules.form" :model="form" label-position="left">
          <!-- 媒体选择 -->
          <el-form-item label="媒体" prop="media">
            <el-select v-model="form.media" collapse-tags class="w-160" key="media" multiple>
              <el-option v-for="item in mediaList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 情感选择 -->
          <el-form-item label="情感属性">
            <el-select v-model="form.emotion" class="w-160" key="emotion">
              <el-option v-for="item in emotionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 关键词 -->
          <el-form-item label="关键词锁定" class="input_group" key="focus">
            <div>
              <el-input placeholder="多个关键词请用英文逗号隔开" class="w-280 mar-r-20" v-model="form.focus.must"></el-input>
              <span class="fz-12">关键词关系：</span>
              <el-radio-group class="yhh" v-model="form.focus.op">
                <el-radio label="and">与</el-radio>
                <el-radio label="or">或</el-radio>
              </el-radio-group>
            </div>
            <div class="mar-t-10">
              <span class="mar-r-10 fz-12">排除</span>
              <el-input placeholder="多个关键词请用英文逗号隔开" class="w-246" v-model="form.focus.not"></el-input>
            </div>
          </el-form-item>
          <div class="mar-t-10"></div>
          <el-form-item label="信息等级">
            <el-radio-group v-model="form.msg_level">
              <el-radio label="all">全部</el-radio>
              <el-radio label="normal">精准信息</el-radio>
              <el-radio label="garbage">噪音信息</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="定向信源" class="dxxy" prop="source_focus_type">
            <el-radio-group v-model="form.source_focus_type" class="radio_group">
              <el-radio label="all">全部</el-radio>
              <el-radio label="include">定向监测</el-radio>
              <div v-show="form.source_focus_type === 'include'" :class="{'w-120': true, 'mar-r-20': true, 'pos-r': true, 'f-l': true, selected: sourceFocus.include.show}">
                <el-input class="w-120" placeholder="请选择" suffix-icon="el-icon-arrow-down" :readonly="true" v-model="sourceFocus.include.name" @focus="sourceFocus.include.show = true" @blur="sourceBlur('include')" />
                <div class="dxxy-cont" v-if="sourceFocus.include.show">
                  <ul>
                    <li v-for="(item, index) in sourceIncludeList" :key="index" class="selected" @click="selectedSource(item, 'include')">
                      <span>{{ item.label }}</span>
                      <i class="iconfont icon-bianji cur-p" @click="editSourcePlan(item.value)" />
                    </li>
                  </ul>
                  <p class="add" @click="createSource('include')">
                    <i class="iconfont icon-zengjia f-l" />
                    <span>新增信源方案</span>
                  </p>
                </div>
              </div>

              <el-radio label="exclude" class="mar-l-10">定向排除</el-radio>
              <div v-show="form.source_focus_type === 'exclude'" :class="{'w-120': true, 'mar-r-20': true, 'pos-r': true, 'f-l': true, selected: sourceFocus.exclude.show}">
                <el-input class="w-120" placeholder="请选择" suffix-icon="el-icon-arrow-down" :readonly="true" v-model="sourceFocus.exclude.name" @focus="sourceFocus.exclude.show = true" @blur="sourceBlur('exclude')" />
                <div class="dxxy-cont" v-if="sourceFocus.exclude.show">
                  <ul>
                    <li v-for="(item, index) in sourceExcludeList" :key="index" class="selected" @click="selectedSource(item, 'exclude')">
                      <span>{{ item.label }}</span>
                      <i class="iconfont icon-bianji cur-p" @click="editSourcePlan(item.value)" />
                    </li>
                  </ul>
                  <p class="add" @click="createSource('exclude')">
                    <i class="iconfont icon-zengjia f-l" />
                    <span>新增信源方案</span>
                  </p>
                </div>
              </div>
            </el-radio-group>
          </el-form-item>
          <!-- 信息匹配 -->
          <el-form-item label="信息匹配" class="mar-t-10">
            <el-radio-group v-model="form.field" key="field">
              <el-radio v-for="item in infomatchList" :key="item.label" :label="item.label">{{ item.value }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 信息地区 -->
          <el-form-item label="信息地区">
            <el-radio-group v-model="form.area_cn" key="area_cn">
              <el-radio v-for="item in regionList" :key="item.label" :label="item.label">{{ item.value }}
              </el-radio>
            </el-radio-group>
            <el-select v-if="form.area_cn === 'customer'" placeholder="请选择信息地区" class="mrsck mar-l-10" v-model="form.area_customer" key="customer-area_cn">
              <el-option v-for="item in province" :key="item" :value="item" :label="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 微博类型 -->
          <el-form-item label="微博类型">
            <el-radio-group v-model="form.weibo_forward" key="weibo_forward">
              <el-radio v-for="item in weiboList" :key="item.label" :label="item.label">{{ item.value }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 微博用户类型 -->
          <el-form-item label="微博用户类型">
            <el-radio-group v-model="form.weibo_usertype" key="weibouser">
              <el-radio v-for="item in weiboUserList" :key="item.label" :label="item.label">{{ item.value }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 作者 -->
          <el-form-item label="作者">
            <el-input placeholder="多个作者请用英文逗号隔开" class="w-280" v-model="form.author"></el-input>
          </el-form-item>
          <!-- 语言 -->
          <el-form-item label="语言">
            <el-radio-group v-model="form.lang" key="lang">
              <el-radio v-for="item in languageList" :key="item.label" :label="item.label">{{ item.value }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <div class="button-group">
              <el-button type="primary" round @click="prevStep">上一步</el-button>
              <el-button type="primary" round @click="generate()" v-loading="generateLoading" :disabled="generateLoading">下一步</el-button>
              <el-button round @click="reset()">重置</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!--@end 高级筛选 -->

      <!-- 完成 -->
      <div v-show="step === 4" class="finished t-c">
        <div class="">
          <i class="el-icon-success"></i>
          <h4>简报制作成功</h4>
          <p>预计1分钟可以查看</p>
        </div>
        <ul>
          <li>简报名称：{{ where.name }}</li>
          <li>提交时间：{{ getTime() }}</li>
        </ul>
        <div class="button-group">
          <el-button round @click="toIndex">返回</el-button>
          <el-button round type="primary" @click="toList()">查看列表</el-button>
        </div>
      </div>
      <!--@end 完成 -->
    </el-card>

    <el-dialog :visible.sync="limitDialog.visible" class="Dialog_style2 kyzh" :close-on-click-modal="false">
      <el-row class="w-100 dp_box">
        <el-col :span="13">
          <div class="img">未购买此模板</div>
        </el-col>
        <el-col :span="11">
          <p class="mar-t-20">您购买的<strong>{{bundle.bundle_name}}</strong></p>
          <p class="mar-t-10">您没有开通该模版，</p>
          <p>购买请联系相关人员</p>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import {
  emotionList,
  weiboList,
  infomatchList,
  regionList,
  weiboUserList,
  languageList,
  briefRight
} from '../constants'
import { themeList, medias as mediaList, province, pickerOptions } from '@/utils/constants'
import { dateRangeDisabled, sourceFocusId, getMenuForId } from '@/utils/helpers'
import { getList } from '@/api/directional-source'
import { generateBriefing, checkForm } from '@/api/message'
import { mapGetters } from 'vuex'
const { _ } = window
export default {
  name: 'plan-brief',
  components: {
  },
  props: {
    templateList: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters(['plans', 'menus', 'bundle'])
  },
  data() {
    const validateFocus = (rule, value, callback) => {
      if (this.form.source_focus_type !== 'all' && !sourceFocusId(this.form)) {
        callback(new Error('定向信源不能为空'))
      } else {
        callback()
      }
    }
    return {
      pickerOptions,
      themeList,
      province,
      mediaList,
      languageList,
      emotionList,
      weiboUserList,
      infomatchList,
      weiboList,
      regionList,
      briefRight: JSON.parse(JSON.stringify(briefRight)),
      showDaily: true,
      template: 'sys',
      step: 1,
      sourceIncludeList: [], // 定向信源 定向检测列表
      sourceExcludeList: [], // 定向信源 定向排除列表
      sourceFocus: {
        include: {
          show: false,
          name: ''
        },
        exclude: {
          show: false,
          name: ''
        }
      },
      where: {
        plan_id: '',
        span: '',
        name: '',
        title: '',
        theme_color: themeList[0].label,
        type: 'classic',
        custom_template_id: '',
      },
      form: {
        emotion: 'all',
        media: mediaList,
        focus: {
          must: '',
          op: 'and',
          not: ''
        },
        msg_level: 'normal',
        area_customer: province[0], // 自定义的信息区域
        weibo_forward: weiboList[0].label,
        weibo_usertype: weiboUserList[0].label,
        lang: languageList[0].label,
        author: '',
        field: infomatchList[0].label,
        area_cn: regionList[0].label,
        source_focus_type: 'all',
        source_focus_include_id: '', // 定向监测排除 ID,
        source_focus_exclude_id: ''
      },
      setting: {
        open: false,
        plan_ids: [''],
        dimensions: [{ name: '维度一', exps: '' }]
      },
      numberMap: ['一', '二', '三', '四', '五', '六'],
      generateLoading: false,
      timeoutFlag: null,
      limitDialog: {
        visible: false
      },
      rules: {
        where: {
          plan_id: [
            { required: true, message: '请选择方案', trigger: 'change' }
          ],
          span: [
            { required: true, message: '请选择简报生成时间范围', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入简报名称', trigger: 'blur' }
          ]
        },
        form: {
          media: [
            { required: true, message: '媒体类型不能为空', trigger: 'blur' }
          ],
          source_focus_type: [
            { validator: validateFocus, trigger: 'blur' }
          ]
        }
      },
      disabledNext: false
    }
  },
  mounted() {
    this.init()
    this.loadSourceData()
  },
  destroyed() {
    if (this.timeoutFlag) {
      clearTimeout(this.timeoutFlag)
    }
  },
  watch: {
    where: {
      handler: function() {
        const { where } = this
        if (where.type && !this.briefRight[where.type]) {
          this.limitDialog.visible = true
          this.disabledNext = true
        } else {
          this.disabledNext = false
        }
      },
      deep: true
    }
  },
  methods: {
    init() {
      this.step = 1
      this.generateLoading = false
      this.resetForm('where')

      // id 6为简报制作
      const brief = getMenuForId(this.menus, 6)
      if (brief !== undefined) {
        Object.assign(this.briefRight, brief.submodule.briefReport)

        if (!this.briefRight[this.where.type]) {
          this.limitDialog.visible = true
        }
      }
    },
    getTime() {
      return window.$moment().format('YYYY-MM-DD HH:mm:ss')
    },
    addItem(type) {
      switch (type) {
        case 'dimension':
          if (this.setting.dimensions.length >= 6) {
            this.$message.warning('最多只能添加6组竞品维度')
            return false
          }
          let i = this.setting.dimensions.length
          let name = this.calculationDefaultName(`维度${this.numberMap[i]}`)
          this.setting.dimensions = this.setting.dimensions.concat([{ name: name, exps: '' }])
          break
        case 'plan':
          if (this.setting.plan_ids.length >= 6) {
            this.$message.warning('最多只能添加6组竞品方案')
            return false
          }
          this.setting.plan_ids = this.setting.plan_ids.concat([''])
          break
      }
    },
    calculationDefaultName(name) {
      const names = this.setting.dimensions.map(function(item) {
        return item.name
      })
      if (names.indexOf(name) !== -1) {
        for (let i in this.numberMap) {
          let newName = `维度${this.numberMap[i]}`
          if (names.indexOf(newName) === -1) return newName
        }
      }
      return name
    },
    virtualEnter(key, id, dimension) {
      this.$nextTick(() => {
        let element = window.document.getElementById(id)
        let pos = element.selectionStart
        let prefix = dimension.exps.substr(0, pos)
        let suffix = dimension.exps.substr(pos)
        dimension.exps = `${prefix}${key}${suffix}`
        // 设置光标位置
        if (element.setSelectionRange) {
          //兼容火狐,谷歌
          setTimeout(function() {
            element.setSelectionRange(pos + 1, pos + 1)
            element.focus()
          }, 0)
        } else if (element.createTextRange) {
          //兼容IE
          let rng = element.createTextRange()
          rng.move('character', pos + 1)
          rng.select()
        }
      })
    },
    removeItem(type, index) {
      switch (type) {
        case 'dimension':
          if (_.size(this.setting.dimensions) === 1) {
            this.$message.warning('维度不能为空,最少一个')
            return false
          }
          this.setting.dimensions.splice(index, 1)
          break
        case 'plan':
          if (_.size(this.setting.plan_ids) === 1) {
            this.$message.warning('竞品不能为空，最少一个')
            return false
          }
          this.setting.plan_ids.splice(index, 1)
          break
      }
    },
    loadSourceData() {
      getList({ page: 1, size: 100 }).then(res => {
        const { state, data } = res.data
        if (state) {
          data.map(item => {
            const { name, id } = item
            if (item.type === 'exclude') {
              this.sourceExcludeList.push({
                label: name,
                value: id
              })
            } else {
              this.sourceIncludeList.push({
                label: name,
                value: id
              })
            }
          })
        }
      }).catch(err => {
        window.console.error(err)
      })
    },
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields()
      })
    },
    createSource(type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        return false
      }

      this.$router.push({ name: `orientation-${type}` })
    },
    sourceBlur(type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        return false
      }

      setTimeout(() => {
        this.sourceFocus[type].show = false
      }, 200)
    },
    editSourcePlan(id) {
      const plan = _.find(this.orientationSourceList, { id: id })

      if (plan !== undefined) {
        this.$router.push({ name: 'orientation-update', params: { data: JSON.stringify(plan) } })
      }
    },
    selectedSource(source, type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        return false
      }

      if (type === 'include') {
        this.form.source_focus_include_id = source.value
        this.sourceFocus.include.name = source.label
      } else {
        this.form.source_focus_exclude_id = source.value
        this.sourceFocus.exclude.name = source.label
      }
    },
    dateRange(date) {
      return dateRangeDisabled(date)
    },
    dateSpanChange(date) {
      if (!date) {
        this.showDaily = true
        return false
      }

      if (date && date.length === 2) {
        const begin = new Date(window.$moment(date[0]).valueOf())
        const end = new Date(window.$moment(date[1]).valueOf())
        const diff = end.getTime() - begin.getTime()
        this.showDaily = Math.abs(diff - 86400000) <= 1000
      }
    },
    toList() {
      this.$emit('tabChange', 'list')
      this.$message.closeAll()
    },
    generate() {
      const { where, form, template } = this

      const params = {
        plan_id: where.plan_id,
        span: where.span.join('~'),
        emotion: form.emotion,
        lang: form.lang,
        media: form.media.join(','),
        area: form.area_cn === 'customer' ? form.area_customer : form.area_cn,
        focus: form.focus,
        field: form.field,
        similar: 'no',
        name: where.name,
        op: 'briefing',
        type: where.type,
        msg_level: form.msg_level,
        theme_color: where.theme_color,
        subtitle: where.title
      }

      if (template === 'custom') {
        params.type = 'custom'
        params.custom_template_id = where.custom_template_id
      }

      if (where.type === 'daily_mix') {
        params.dimensions = this.setting.dimensions.filter(dimension => {
          return dimension.exps.length >= 1
        })
        params.dimensions = JSON.stringify(params.dimensions)
        params.vs_plans = this.setting.plan_ids.filter(id => {
          return id
        })
        params.vs_plans = JSON.stringify(params.vs_plans)
      }

      this.generateLoading = true
      generateBriefing(params).then(res => {
        this.generateLoading = false
        if (res.data.state) {

          const h = this.$createElement;
          this.$message({
            type: 'success',
            message:
              h('p', null,
              [
                h('span', { class: 'cor-67c'}, '报告提交成功。'),
                h('a', { attrs: { 'href': "#" }, style:{'font-size':'13px'}, class: 'class="cor-575 underline', on: { click: () => { this.toList() } } }, '点击查看')
              ]
              )
          }),

          this.nextStep('form')
          // this.timeoutFlag = setTimeout(() => {
          //   this.toList()
          //   this.toIndex()
          // }, 3000)
        } else {
          this.$message.error(res.data.error)
        }
      }).catch(e => {
        this.generateLoading = false
        window.console.error(e)
      })
    },
    nextStep(formName, checkFormSwitch=false) {
      if (checkFormSwitch) {
        checkForm(
          {'name': this.where.name, 'type': 'plan'}
        ).then(res => {
          const data = res.data
          if (!data.state) {
            this.$message.warning(data.error)
            return false;
          }else{
            this.nextStep_o(formName)
          }
        }).catch(err => {
          window.console.error(err)
        })
      }else{
        this.nextStep_o(formName)
      }

    },
    nextStep_o(formName){
      this.$nextTick(() => {
        const { step } = this
        if (this.template === 'custom' && !this.where.custom_template_id) {
          return false
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (step < 4) {
              this.step = step + 1
            }
          } else {
            return false
          }
        })
      })
    },
    prevStep() {
      const { step } = this
      if (step <= 1) {
        this.toIndex()
        return false
      }

      this.step = step - 1
    },
    toIndex() {
      this.$emit('change', 'select')
    },
    reset() {
      Object.assign(this.setting, {
        open: false,
        plan_ids: [''],
        dimensions: [{ name: '维度一', exps: '' }]
      })
      Object.assign(this.form, {
        emotion: 'all',
        media: mediaList,
        focus: {
          must: '',
          op: 'and',
          not: ''
        },
        msg_level: 'normal',
        area_customer: province[0], // 自定义的信息区域
        weibo_forward: weiboList[0].label,
        weibo_usertype: weiboUserList[0].label,
        lang: languageList[0].label,
        author: '',
        field: infomatchList[0].label,
        area_cn: regionList[0].label,
        source_focus_type: 'all',
        source_focus_include_id: '', // 定向监测排除 ID,
        source_focus_exclude_id: ''
      })
    }
  }
};
</script>
<style scoped>
.plan-brief {
  margin: 0 20px 20px 10px;
  height: calc(100% - 20px);
  width: calc(100% - 30px);
  flex-direction: column;
}
.plan-brief-cont {
  padding: 20px 40px;
  overflow: auto;
}
.plan-brief-cont >>> .el-form-item--small.el-form-item {
  margin-bottom: 20px;
}
.select-template .theme_colors {
  float: left;
}
.select-template >>> .el-form-item {
  flex: 1;
}
.select-template .theme_colors:not(:last-child) {
  margin-right: 30px;
}
.system-template {
  width: 900px;
  margin: 30px auto 0;
}
.system-template >>> .el-card__body {
  padding: 0;
  position: relative;
}
.system-template img {
  width: 900px;
  height: 124px;
}
.system-template .title,
.system-template .sub-title {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
}
.system-template .title {
  line-height: 120px;
  font-size: 16px;
  color: #fff;
}
.system-template .sub-title {
  line-height: 180px;
  color: rgba(255, 255, 255, 0.76);
  font-weight: 400;
  font-size: 14px;
}
.system-template .summary {
  line-height: 160px;
  text-align: center;
  font-size: 12px;
}
/* 完成 */
.finished >>> .el-icon-success {
  color: #67c23a;
  font-size: 40px;
}
.finished h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
}
.finished p {
  font-size: 12px;
  color: #787878;
}
.finished ul {
  padding: 20px 150px;
  background: #fbfbfb;
  margin: 50px auto;
  display: inline-block;
}
.finished li {
  font-size: 14px;
  color: #333;
  line-height: 36px;
  text-align: left;
}

/* 高级设置 */
.gjsz_btn {
  margin-bottom: 20px;
}
.gjsz {
  margin-left: -10px;
}
.gjsz_cont {
  padding: 20px 30px;
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 20px;
}
.gjsz_cont >>> .el-form-item--small.el-form-item {
  margin-bottom: 0;
}
.gjsz_cont >>> .w-120 .el-input .el-input__suffix {
  top: -15px;
}
.gjsz_cont >>> .el-textarea {
  width: calc(100% - 35px);
}
.gjsz_cont >>> .el-textarea__inner {
  line-height: 27px;
  padding: 2px 10px;
}
.fh_btn_group .el-button--small {
  padding: 0;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  float: left;
  margin-right: 5px;
}
.fh_btn_group .el-button + .el-button {
  margin-right: 5px;
}
.bd_n.circle-plus {
  border: none;
  padding-left: 0;
  line-height: 18px;
  display: flex;
  background: none;
}
.circle-plus >>> .iconfont icon-zengjia {
  font-size: 20px;
  line-height: 18px;
}
.circle-plus span {
  display: inline-block;
}
.wdfx_cont .item:not(:last-child),
.wdfx_cont .item:not(:last-child) .fh_btn_group .el-button--small {
  margin-bottom: 10px;
}
.wdfx_cont .item:only-child,
.wdfx_cont .item:only-child .fh_btn_group .el-button--small {
  margin-bottom: 0;
}
.gjsz_cont >>> .el-input__count {
  height: 32px;
}
.dxxy .selected >>> .el-icon-arrow-down {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.dxxy >>> .el-radio {
  float: left;
  margin-top: 5px;
}
.dxxy-cont {
  position: absolute;
  left: 0;
  top: 35px;
  z-index: 10;
  width: 180px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  padding: 6px 0;
}
.dxxy-cont ul {
  max-height: 145px;
  overflow: auto;
}
.dxxy-cont li,
.dxxy-cont .add {
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  display: flex;
}
.dxxy-cont li span,
.dxxy-cont .add span {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  cursor: pointer;
}
.dxxy-cont li .icon-bianji {
  display: none;
}
.dxxy-cont li:hover {
  background: rgba(62, 71, 255, 0.1);
}
.dxxy-cont li:hover span {
  color: #3e47ff;
}
.dxxy-cont li:hover .icon-bianji {
  display: block;
}
.dxxy-cont li i {
  font-size: 14px;
  line-height: 35px;
}
.dxxy-cont .add {
  border-top: 1px solid #f2f2f2;
}
</style>
