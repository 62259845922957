<template>
  <div class="plan-brief flex">
    <el-card class="mar-t-20">
      <el-steps :active="step" finish-status="success" align-center>
        <el-step title="基本信息" description="编辑简报的基本信息"></el-step>
        <el-step title="选择模版" description="选择简报生成后的样式"></el-step>
        <el-step title="完成" description="完成简报制作"></el-step>
      </el-steps>
    </el-card>
    <el-card class="plan-brief-cont mar-t-20 flex-1">
      <!-- 基本信息 -->
      <div v-show="step === 1">
        <el-form
          ref="where"
          :model="where"
          :rules="rules.where"
          label-width="80px"
        >
          <el-form-item label="收藏夹" prop="mid">
            <el-select
              v-model="where.mid"
              placeholder="请选择收藏夹"
              class="w-200"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in materials"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="简报名称" prop="name">
            <el-input
              type="text"
              placeholder="请输入报告名称"
              v-model="where.name"
              maxlength="10"
              show-word-limit
              class="w-200"
            />
          </el-form-item>
          <el-form-item label="副标题">
            <el-input
              class="w-450"
              type="text"
              placeholder="(非必选)请输入副标题"
              v-model="where.title"
              maxlength="30"
              show-word-limit
            />
          </el-form-item>
          <el-form-item>
            <div class="button-group">
              <el-button type="primary" round @click="prevStep"
                >上一步</el-button
              >
              <el-button type="primary" round @click="nextStep('where', true)"
                >下一步</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!--@end 基本信息 -->
      <!-- 选择模版 -->
      <div v-show="step === 2" class="select-template">
        <el-form class="flex space-between">
          <el-form-item label="主题颜色">
            <div
              v-for="item in themeList"
              :key="item.label"
              class="theme_colors"
            >
              <el-radio :label="item.label" v-model="where.theme_color">{{
                item.name
              }}</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="模版选择" required>
            <el-radio-group v-model="template">
              <el-radio label="sys"
                >系统模版
                <el-select
                  v-show="template === 'sys'"
                  v-model="where.type"
                  placeholder="请选择"
                  class="w-200 mar-l-10"
                >
                  <el-option label="经典统计模版" value="classic" />
                  <el-option label="重点信息模版" value="enhance" />
                  <el-option label="信息摘要模板" value="abstract" />
                </el-select>
              </el-radio>
              <el-radio label="custom"
                >自定义模版
                <el-select
                  no-data-text="暂无模板"
                  v-show="template === 'custom'"
                  v-model="where.custom_template_id"
                  placeholder="请选择"
                  class="w-200 mar-l-10"
                >
                  <el-option
                    :label="template.name"
                    :value="template.id"
                    v-for="(template, index) in templateList"
                    :key="index"
                  />
                </el-select>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-card class="system-template">
          <img
            src="@/assets/images/common/top_banner_blue.png"
            class="banner"
            v-show="where.theme_color === 'blue'"
          />
          <img
            src="@/assets/images/common/top_banner_red.png"
            class="banner"
            v-show="where.theme_color === 'red'"
          />
          <img
            src="@/assets/images/common/top_banner_black.png"
            class="banner"
            v-show="where.theme_color === 'black'"
          />
          <h3 class="title">{{ where.name }}</h3>
          <h4 class="sub-title">
            {{ where.title }}
          </h4>
          <p class="summary">
            <i
              class="iconfont icon-select-brief mar-r-10"
            />请选择您要使用的模版～
          </p>
        </el-card>
        <div class="button-group mar-t-45">
          <el-button type="primary" round @click="prevStep">上一步</el-button>
          <el-button
            type="primary"
            round
            @click="generate()"
            :disabled="generateLoading"
            v-loading="generateLoading"
            >下一步</el-button
          >
        </div>
      </div>
      <!--@end 选择模版 -->
      <!-- 完成 -->
      <div v-show="step === 3" class="finished t-c">
        <div class="">
          <i class="el-icon-success"></i>
          <h4>简报制作成功</h4>
          <p>预计1分钟可以查看</p>
        </div>
        <ul>
          <li>简报名称：{{ where.name }}</li>
          <li>提交时间：{{ getTime() }}</li>
        </ul>
        <div class="button-group">
          <el-button round @click="toIndex">返回</el-button>
          <el-button round type="primary" @click="toList()">查看列表</el-button>
        </div>
      </div>
      <!--@end 完成 -->
    </el-card>
  </div>
</template>
<script>
import { themeList } from '@/utils/constants';
import { submit } from '@/api/briefing';
import { checkForm } from '@/api/message';
import { mapGetters } from 'vuex';
export default {
  name: 'plan-material',
  components: {},
  props: {
    templateList: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      step: 1,
      themeList,
      template: 'sys',
      generateLoading: false,
      timeoutFlag: null,
      where: {
        mid: '',
        name: '',
        title: '',
        theme_color: themeList[0].label,
        type: 'classic',
        custom_template_id: ''
      },
      rules: {
        where: {
          mid: [{ required: true, message: '请选择收藏夹', trigger: 'change' }],
          name: [{ required: true, message: '请输入简报名称', trigger: 'blur' }]
        }
      }
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(['materials', 'menus', 'bundle'])
  },
  destroyed() {
    if (this.timeoutFlag) {
      clearTimeout(this.timeoutFlag);
    }
  },
  methods: {
    init() {
      this.step = 1;
    },
    getTime() {
      return window.$moment().format('YYYY-MM-DD HH:mm:ss');
    },
    generate() {
      const { where, template } = this;
      if (!where.mid) {
        this.$message.warning('请选择收藏夹。');
        return false;
      }

      if (!where.name) {
        this.$message.warning('简报名称不能为空。');
        return false;
      }

      this.generateLoading = true;
      const data = {
        mid: where.mid,
        type: where.type,
        name: where.name,
        theme_color: where.theme_color,
        subtitle: where.title
      };

      if (template === 'custom') {
        data.type = 'custom';
        data.custom_template_id = where.custom_template_id;
      }

      submit(data)
        .then(res => {
          this.generateLoading = false;
          if (res.data.state) {
            this.$message.success('简报生成中，跳转至简报查看');
            this.nextStep('where');
            this.timeoutFlag = setTimeout(() => {
              this.toList();
              this.toIndex();
            }, 3000);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(e => {
          this.generateLoading = false;
          window.console.error(e);
        });
    },
    nextStep(formName, checkFormSwitch = false) {
      if (checkFormSwitch) {
        checkForm({ name: this.where.name, type: 'material' })
          .then(res => {
            const data = res.data;
            if (!data.state) {
              this.$message.warning(data.error);
              return false;
            } else {
              this.nextStep_o(formName);
            }
          })
          .catch(err => {
            window.console.error(err);
          });
      } else {
        this.nextStep_o(formName);
      }
    },
    nextStep_o(formName) {
      this.$nextTick(() => {
        const { step } = this;
        if (this.template === 'custom' && !this.where.custom_template_id) {
          return false;
        }
        this.$refs[formName].validate(valid => {
          if (valid) {
            if (step < 4) {
              this.step = step + 1;
            }
          } else {
            return false;
          }
        });
      });
    },
    prevStep() {
      const { step } = this;
      if (step <= 1) {
        this.toIndex();
        return false;
      }

      this.step = step - 1;
    },
    toIndex() {
      this.$emit('change', 'select');
    },
    toList() {
      this.$emit('tabChange', 'list');
    }
  }
};
</script>
<style scoped>
.plan-brief {
  margin: 0 20px 20px 10px;
  height: calc(100% - 20px);
  width: calc(100% - 30px);
  flex-direction: column;
}
.plan-brief-cont {
  padding: 20px 40px;
  overflow: auto;
}
.plan-brief-cont >>> .el-form-item--small.el-form-item {
  margin-bottom: 20px;
}
.select-template .theme_colors {
  float: left;
}
.select-template >>> .el-form-item {
  flex: 1;
}
.select-template .theme_colors:not(:last-child) {
  margin-right: 30px;
}
.system-template {
  width: 900px;
  margin: 30px auto 0;
}
.system-template >>> .el-card__body {
  padding: 0;
  position: relative;
}
.system-template img {
  width: 900px;
  height: 124px;
}
.system-template .title,
.system-template .sub-title {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
}
.system-template .title {
  line-height: 120px;
  font-size: 16px;
  color: #fff;
}
.system-template .sub-title {
  line-height: 180px;
  color: rgba(255, 255, 255, 0.76);
  font-weight: 400;
  font-size: 14px;
}
.system-template .summary {
  line-height: 160px;
  text-align: center;
  font-size: 12px;
}
/* 完成 */
.finished >>> .el-icon-success {
  color: #67c23a;
  font-size: 40px;
}
.finished h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
}
.finished p {
  font-size: 12px;
  color: #787878;
}
.finished ul {
  padding: 20px 150px;
  background: #fbfbfb;
  margin: 50px auto;
  display: inline-block;
}
.finished li {
  font-size: 14px;
  color: #333;
  line-height: 36px;
  text-align: left;
}
</style>
