export const dataImportList = [
  {
    value: 'common',
    label: ' 常用简报方案',
  },
  {
    value: 'new',
    label: ' 新简报方案',
  },
  {
    value: 'material',
    label: ' 素材库导入',
  },
];

export const dynamicTimeList = [
  {
    value: 'td',
    label: '今日',
  },
  {
    value: 'd',
    label: '24小时',
  },
  {
    value: 'tw',
    label: '本周',
  },
  {
    value: 'w',
    label: '近7天',
  },
  {
    value: 'tm',
    label: '本月',
  },
  {
    value: 'm',
    label: '近30天',
  },
];

export function getDynamicSpan(unit = 'td') {
  const end = new Date()
  const start = new Date()
  let span = ''

  switch (unit) {
    case 'td':
      span = [`${window.$moment(start).format('YYYY-MM-DD')} 00:00:00`, window.$moment(end).format('YYYY-MM-DD HH:mm:ss')]
      break
    case 'd':
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)

      span = [window.$moment(start).format('YYYY-MM-DD HH:mm:ss'), window.$moment(end).format('YYYY-MM-DD HH:mm:ss')]
      break
    case 'tw':
      const weekday = start.getDay() || 7
      start.setDate(start.getDate() - weekday + 1)

      span = [`${window.$moment(start).format('YYYY-MM-DD')} 00:00:00`, window.$moment(end).format('YYYY-MM-DD HH:mm:ss')]
      break
    case 'w':
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)

      span = [window.$moment(start).format('YYYY-MM-DD HH:mm:ss'), window.$moment(end).format('YYYY-MM-DD HH:mm:ss')]
      break
    case 'tm':
      start.setDate(1)

      span = [window.$moment(start).format('YYYY-MM-DD HH:mm:ss'), window.$moment(end).format('YYYY-MM-DD HH:mm:ss')]
      break
    case 'm':
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)

      span = [window.$moment(start).format('YYYY-MM-DD HH:mm:ss'), window.$moment(end).format('YYYY-MM-DD HH:mm:ss')]
      break
  }

  return span
}

export const emotionList = [
  {
    value: 'all',
    label: '全部',
  },
  {
    value: 'positive',
    label: '非敏感',
  },
  {
    value: 'negative',
    label: '敏感',
  },
];

export const infomatchList = [
  {
    value: '全文匹配',
    label: 'content',
  },
  {
    value: '标题匹配',
    label: 'title',
  },
];

export const regionList = [
  {
    value: '全部',
    label: 'all',
  },
  {
    value: '境内',
    label: 'cn',
  },
  {
    value: '境外',
    label: 'other',
  },
  {
    value: '自定义',
    label: 'customer',
  }
];

export const weiboList = [
  {
    value: '不限',
    label: 'all',
  },
  {
    value: '原创',
    label: 'no',
  },
  {
    value: '转发',
    label: 'yes',
  },
];

export const weiboUserList = [
  {
    value: '不限',
    label: 'all',
  },
  {
    value: '普通用户',
    label: '普通用户',
  },
  {
    value: '黄V',
    label: '黄V',
  },
  {
    value: '金V',
    label: '金V',
  },
  {
    value: '蓝V',
    label: ' 蓝V',
  },
  {
    value: '达人',
    label: '达人',
  },
  {
    value: '微博女郎',
    label: '微博女郎'
  }
];

export const languageList = [
  {
    value: '全部',
    label: 'all',
  },
  {
    value: '中文',
    label: 'zh'
  },
  {
    value: '其他',
    label: 'en',
  },
];
// 常用简报方案 和 新简报方案排序
export const orderList1 = [
  {
    value: 'intelligence',
    label: '智能排序'
  },
  {
    value: 'default',
    label: '默认相关度',
  },
  {
    value: 'importance',
    label: '重要度排序',
  },
  {
    value: 'desc',
    label: '时间降序',
  },
  {
    value: 'asc',
    label: '时间升序',
  },
];

// 素材库导入排序
export const orderList2 = [
  {
    value: 'join_desc',
    label: '加入素材时间降序',
  },
  {
    value: 'join_asc',
    label: '加入素材时间升序',
  },
  {
    value: 'pub_desc',
    label: '发布时间降序',
  },
  {
    value: 'pub_asc',
    label: '发布时间升序',
  },
];

// 后段返回的字段映射
export const timeMap = {
  td: '今天',
  d: '24小时',
  tw: '本周',
  w: '近7天',
  tm: '本月',
  m: '近30天'
};

export const templateMap = {
  enhance: '重点信息模板',
  classic: '经典统计模板',
  daily_common: '日报常用模版',
  daily_mix: '日报混合模版',
  abstract: '信息摘要模板',
  custom: '自定义模板'
}

export const emotionMap = {
  all: '全部',
  positive: '非敏感',
  negative: '敏感',
}

export const briefRight = {
  abstract: false,
  classic: false,
  daily_common: false,
  daily_mix: false,
  enhance: false
}
