<template>
  <el-card class="brief_v2_list">
    <div class="history-cont">
      <el-row class="empty" v-if="!loading && list.length < 1">
        <div>
          <img
            src="@/assets/images/common/empty/lsjbqd.svg"
            alt="历史简报清单"
            width="400"
            class="img"
            type="image/svg+xml"
          />
          <p><span>历史简报什么也没有，快去生成简报吧</span></p>
        </div>
      </el-row>
      <div v-loading="loading" v-else class="min-h-550">
        <div>
          <ul class="mod-list average-list lh-60">
            <li>
              <h3>
                <span class="num-tit">序号</span>
                <span class="tag">简报名称</span>
                <span class="tag">简报模版</span>
                <span class="tag">数量</span>
                <span class="tag">日期</span>
                <span class="tag">创建者</span>
                <span class="tag">状态</span>
                <span class="tag"
                  ><i class="b" style="margin-left:-30px;">操作</i></span
                >
              </h3>
            </li>
            <li v-for="(row, index) in list" :key="index" class="item">
              <span class="num bg-555" v-if="index == 0">1</span>
              <span class="num bg-689" v-else-if="index == 1">2</span>
              <span class="num bg-8ea" v-else-if="index == 2">3</span>
              <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
              <span class="tag">{{ row.name }}</span>
              <span class="tag">{{ templateMap[row.type] }}</span>
              <span class="tag">{{ row.number }}条</span>
              <span class="tag">{{ row.created }}</span>
              <span class="tag"> {{ row.createdby_name }}</span>
              <span class="tag">
                <!-- 状态 -->
                <i
                  class="iconfont icon-Loading Rotation"
                  v-if="row.state === 'run'"
                />
                <i v-else-if="row.state === 'succ'">已生成</i>
                <i v-else-if="row.state === 'wait'">等待中</i>
                <i v-else-if="row.state === 'fail'">生成失败</i>
              </span>
              <span class="tag">
                <div class="ico-group">
                  <!-- 查看 -->
                  <var :class="{ jy: row.state !== 'succ' }">
                    <el-tooltip
                      effect="dark"
                      content="查看简报"
                      placement="bottom"
                    >
                      <i class="iconfont icon-chakan" @click="view(row)" />
                    </el-tooltip>
                  </var>
                  <!-- 下载 -->
                  <var :class="{ jy: row.state !== 'succ' }">
                    <el-dropdown
                      v-if="row.state === 'succ'"
                      @click="downloadRow(row)"
                    >
                      <span class="el-dropdown-link">
                        <i class="iconfont icon-xiazai" />
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <i
                            class="iconfont icon-pdf"
                            @click="downloadFile(row, 'pdf')"
                          >
                            <span>导出PDF</span>
                          </i>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <i
                            class="iconfont icon-word"
                            @click="downloadFile(row, 'word')"
                          >
                            <span>导出word</span>
                          </i>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <i
                            class="iconfont icon-excel"
                            @click="downloadFile(row, 'excel')"
                          >
                            <span>导出Excel</span>
                          </i>
                        </el-dropdown-item>
                        <el-dropdown-item v-show="row.type !== 'custom'">
                          <i class="iconfont icon-h5" @click="downloadH5(row)">
                            <span>导出h5</span>
                          </i>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <span class="el-dropdown-link cur-d" v-else>
                      <i class="iconfont icon-xiazai cur-d" />
                    </span>
                  </var>
                  <!-- 查看 -->
                  <var :class="{ jy: row.state !== 'succ' }">
                    <el-tooltip
                      effect="dark"
                      content="发送简报"
                      placement="bottom"
                    >
                      <i
                        class="iconfont icon-fasong"
                        @click="sendBefore(row)"
                      />
                    </el-tooltip>
                  </var>
                  <!-- 删除 -->
                  <var :class="{ jy: row.state !== 'succ' }">
                    <el-tooltip
                      effect="dark"
                      content="删除简报"
                      placement="bottom"
                    >
                      <i
                        class="iconfont icon-shanchu"
                        v-if="row.state === 'succ'"
                        @click="del(index, row)"
                      />
                      <i class="iconfont icon-shanchu" v-else />
                    </el-tooltip>
                  </var>
                </div>
              </span>
            </li>
          </ul>
          <div class="page-bottom">
            <el-pagination
              :hide-on-single-page="singPage"
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :current-page="currentPage"
              :total="page.total"
            />
          </div>
        </div>
      </div>
    </div>
    <confirm-delete
      class="single-information"
      ref="confirm"
      title="删除简报"
      confirm="删除"
      @must="mustDel"
    />
    <!-- 发送弹出框 -->
    <el-dialog
      :visible.sync="sendDialog.visible"
      class="sent-email new-dialog"
      :close-on-click-modal="false"
    >
      <div class="mod-title" slot="title">发送至</div>
      <div class="cont">
        <el-form label-width="80px">
          <el-form-item label="推送方式">
            <el-radio>邮件</el-radio>
            <el-select
              v-model="sendDialog.email"
              placeholder="请选择"
              class="w-200"
            >
              <el-option
                v-for="(contact, index) in sendDialog.contacts"
                :key="index"
                :label="contact.name"
                :value="contact.email"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="（非必填）请输入备注"
              v-model="sendDialog.note"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="button-group">
        <el-button
          round
          type="primary"
          @click="sendBrief"
          v-loading="sendDialog.loading"
          :disabled="sendDialog.loading"
          >确定</el-button
        >
        <el-button round @click="sendDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <!--@end 发送弹出框 -->
  </el-card>
</template>
<script>
import { templateMap } from './constants';
import QRCode from 'qrcodejs2';
import {
  lists as briefingList,
  delItem,
  reportState,
  reportRetry,
  send
} from '@/api/briefing';
import confirmDelete from '@components/common/confirm.vue';
import { getAuthToken } from '@/utils/helpers';
import { getContacts } from '@/api/contacts';
import { mapGetters } from 'vuex';
const { _ } = window;
export default {
  name: 'brief-history',
  components: {
    confirmDelete
  },
  data() {
    return {
      templateMap,
      list: [],
      page: { total: 0 },
      currentPage: 1,
      loading: false,
      nf: null,
      singPage: true,
      sendDialog: {
        visible: false,
        id: '',
        email: '',
        contacts: [],
        note: '',
        loading: false
      }
    };
  },
  props: {
    changeTab: {
      type: Function,
      default: null
    }
  },
  computed: {
    ...mapGetters(['reportPollFlag'])
  },
  mounted() {
    this.loadData();
    this.loadContacts();
    // 点击空白处收起下拉框
    const _this = this;
    this.$nextTick(() => {
      window.$('#app-body').click(function(e) {
        if (
          e.target.className !== 'iconfont icon-xiazai' &&
          e.target.className !== 'iconfont icon-xiazai djh'
        ) {
          _this.list = _this.list.map(item => {
            item.download = false;
            return item;
          });
        }
      });
    });
  },
  watch: {},
  methods: {
    sendBefore(row) {
      this.sendDialog.id = row.id;
      this.sendDialog.email = '';
      this.sendDialog.note = '';
      this.sendDialog.loading = false;

      this.sendDialog.visible = true;
    },
    sendBrief() {
      const { id, note, email } = this.sendDialog;

      if (!email) {
        return false;
      }
      this.sendDialog.loading = true;
      send({ id, note, email })
        .then(res => {
          if (res.data.state) {
            this.$message.success('发送成功')
            this.sendDialog.visible = false
          } else {
            this.$message.error('发送失败');
          }
          this.sendDialog.loading = false;
        })
        .catch(e => {
          window.console.error(e);
        });
    },
    loadContacts() {
      getContacts()
        .then(res => {
          if (res.data.state) {
            const result = res.data.data.filter(item => {
              return item.email.toString().length > 1;
            });

            this.sendDialog.contacts = result;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('加载通讯录失败，服务错误');
        });
    },
    loadData() {
      this.loading = true;
      const _this = this;
      briefingList({ page: this.currentPage })
        .then(res => {
          if (res.data.state) {
            var result = res.data.data;
            _(result.data).forEach((item, i) => {
              result.data[i].download = false;
            });
            this.list = result.data;
            this.page = result.page;
            this.loading = false;

            // 轮询状态
            if (!this.reportPollFlag) {
              const closeNf = () => {
                if (this.nf) {
                  this.nf.close();
                }
              };

              const data = _.orderBy(result.data, ['id', 'asc']);
              _(data).forEach(item => {
                if (item.state === 'wait' || item.state === 'run') {
                  this.$store.commit(
                    'SET_REPORT_POLL_FLAG',
                    setInterval(() => {
                      reportState({ bid: item.id })
                        .then(res => {
                          if (res.data.state) {
                            const { data } = res.data;
                            if (data.state === 'fail') {
                              clearInterval(_this.reportPollFlag);
                              _this.$store.commit('SET_REPORT_POLL_FLAG', null);
                              _this.nf = _this.$notify({
                                type: 'error',
                                title: '失败',
                                dangerouslyUseHTMLString: true,
                                message: this.renderFail(item),
                                offset: 80,
                                duration: 0,
                                onClick: closeNf
                              });
                            } else if (data.state === 'succ') {
                              clearInterval(_this.reportPollFlag);
                              _this.$store.commit('SET_REPORT_POLL_FLAG', null);
                              _this.loadData();
                              _this.nf = _this.$notify({
                                type: 'success',
                                title: '成功',
                                dangerouslyUseHTMLString: true,
                                message: this.renderSucc(),
                                offset: 80,
                                duration: 0,
                                onClick: closeNf
                              });
                            }
                          }
                        })
                        .catch(e => {
                          clearInterval(this.reportPollFlag);
                          window.console.error(e);
                        });
                    }, 6000)
                  );
                  return false;
                }
              });
            }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('获取历史简报清单失败，服务错误');
        });
    },
    // renderSucc() {
    //   return (
    //     <p>
    //       <span class="cor-67c">报告生成成功。</span>
    //       <span
    //         class="cur-p underline cor-575"
    //         on-click={() => {
    //           this.toHistory();
    //         }}
    //       >
    //         点击查看
    //       </span>
    //     </p>
    //   );
    // },
    renderSucc() {
      return (
        <p>
          <span class="cor-67c">报告生成成功。</span>
        </p>
      );
    },
    renderFail(item) {
      return (
        <span class="cor-fc5">
          <span>报告“{item.name}”生成失败，请稍后</span>
          <span
            class="cur-p underline cor-575"
            on-click={() => {
              this.retry(item.id);
            }}
          >
            重试
          </span>
          ，或联系相关人员。
        </span>
      );
    },
    retry(id) {
      reportRetry({ bid: id })
        .then(res => {
          if (res.data.state) {
            this.$message.success('重新提交该简报成功');
            setTimeout(() => {
              this.toHistory();
            }, 2000);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(e => {
          this.$message.error('重试失败，服务错误');
          window.console.error(e);
        });
    },
    toHistory() {
      this.loadData();

      if (this.$route.name === 'brief') {
        if (this.changeTab) {
          this.changeTab('history');
        }
      } else {
        this.$router.push({ name: 'brief', params: { toHistory: true } });
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    downloadRow(row) {
      const _this = this;
      _(this.list).forEach((item, i) => {
        _this.list[i].download = false;
      });
      row.download = true;
    },
    downloadFile(row, type) {
      const token = getAuthToken();
      window.open(
        `${window.service.api}/briefing/download?id=${row.id}&type=${type}&token=${token}`,
        '_blank'
      );
    },
    view(row) {
      if (row.state !== 'succ') {
        return false;
      }
      window.open(
        `${window.service.api}/briefing/report_view?id=${row.id}`,
        '_blank'
      );
    },
    downloadH5(row) {
      row.download = false;
      let url = '';
      switch (row.type) {
        case 'enhance':
          url = `${window.location.origin}/brief/h5/advanced?id=${row.id}&type=h5`;
          break;
        case 'classic':
          url = `${window.location.origin}/brief/h5/classic?id=${row.id}&type=h5`;
          break;
        case 'daily_common':
          url = `${window.location.origin}/brief/h5/common?id=${row.id}&type=h5`;
          break;
        case 'daily_mix':
          url = `${window.location.origin}/brief/h5/mix?id=${row.id}&type=h5`;
          break;
        case 'abstract':
          url = `${window.location.origin}/brief/h5/abstract?id=${row.id}&type=h5`;
          break;
      }
      this.qrcode(url);
    },
    qrcode(url) {
      window
        .jQuery('#qrcode')
        .children()
        .remove();
      const h = this.$createElement;
      this.$confirm('', '', {
        title: '',
        message: h(
          'div',
          {
            style:
              'text-align: center;width: 180px;height: 160px;margin-left: auto;margin-right: auto;margin-top:20px;'
          },
          [
            h('div', {
              style:
                'margin-left: auto;margin-right: auto;width: 120px;height: 120px;margin-bottom: 8px;',
              attrs: { id: 'qrcode' }
            }),
            h(
              'p',
              { style: 'font-size: 14px;line-height:30px;' },
              '扫描二维码，查看报告'
            ),
            h(
              'button',
              {
                attrs: { id: 'qrcode-button' },
                style:
                  'line-height:16px;padding:10px 25px;font-size: 12px;color: #ffffff;border:none;border-radius:20px;margin-top:10px;background:#555DFE;cursor:pointer;'
              },
              '打开浏览器查看'
            )
          ]
        ),
        showConfirmButton: false,
        showCancelButton: false
      })
        .then(() => {})
        .catch(() => {});
      this.$nextTick(() => {
        var but = window.document.getElementById('qrcode-button');
        window.jQuery(but).off('click');
        but.onclick = function() {
          window.open(url, '_blank');
        };
        var qrcode = new QRCode('qrcode', {
          text: '',
          width: 120,
          height: 120,
          colorDark: '#000000',
          colorLight: '#ffffff'
        });
        qrcode.makeCode(url);
      });
    },
    del(index, row) {
      let textBox = [{ type: 'error', text: '您将不能再查看及导出此份报告' }];
      this.$refs.confirm.show(textBox, { id: row.id, index });
    },
    mustDel(data) {
      const { id, index } = data;
      delItem(id)
        .then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            if (this.list.length == 1) {
              this.currentPage = 1;
              this.loadData();
            } else {
              this.list.splice(index, 1);
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.error
            });
          }
        })
        .catch(() => {
          this.$message.error('删除简报失败，服务错误');
        });
    }
  }
};
</script>
<style scoped>
.brief_v2_list {
  margin: 20px 20px 20px 10px;
  padding: 0 40px;
  box-sizing: border-box;
  height: calc(100% - 40px);
  overflow: auto;
}
.brief_v2_list .average-list .tag var {
  float: left;
  margin: 0 12px;
}
.brief_v2_list .ico-group {
  width: 190px;
  margin: 0 auto;
  display: inline-block;
}
</style>
