<template>
  <el-card class="select-brief">
    <h3>选择简报生成方式</h3>
    <el-row justify="center" type="flex">
      <el-col :span="12" class="t-c">
        <div class="brief cur-p" @click="changePage('plan')">
          <img
            type="image/svg+xml"
            src="@/assets/images/brief/fasc.svg"
            alt="方案生成"
          />
        </div>
        <h4>方案生成</h4>
        <p>通过选择已有的方案生成简报</p>
      </el-col>
      <el-col :span="12" class="t-c">
        <div class="brief cur-p" @click="changePage('material')">
          <img
            type="image/svg+xml"
            src="@/assets/images/brief/scjsc.svg"
            alt="收藏夹生成"
          />
        </div>
        <h4>收藏夹生成</h4>
        <p>通过已收藏的内容生成简报</p>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import Sortable from 'sortablejs';
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    changePage(page) {
      this.$emit('change', page);
    },
    initEvent() {
      this.$nextTick(() => {
        const _this = this;
        Sortable.create(document.getElementById('list-group'), {
          onEnd: function() {
            const elements = window.$('#list-group li');
            const data = [];
            for (let i = 0; i < elements.length; i++) {
              const index = window.$(elements[i]).data('id');
              data.push(_this.items[index]);
            }
            _this.data = data;
            window.console.log(data);
          }
        });
      });
    }
  }
};
</script>
<style scoped>
ul li {
  text-align: left;
  background: moccasin;
  margin-top: 10px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
.select-brief {
  margin: 20px 20px 20px 10px;
  padding: 0 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: calc(100% - 40px);
}
.select-brief h3 {
  text-align: center;
  font-size: 16px;
  line-height: 170px;
}
.select-brief .brief {
  width: 520px;
  height: 300px;
  background: url(../../assets/images/common/card_xinxi1.png) no-repeat 0 0 /
    100% 100%;
  margin: 0 auto;
}
.select-brief .brief img {
  display: block;
  margin: 0 auto;
  width: 270px;
  height: 190px;
  padding-top: 40px;
}
.select-brief .brief:hover {
  transform: scale(1.05);
  transform-origin: center center;
}
.select-brief h4 {
  font-size: 14px;
  font-weight: 550;
  line-height: 40px;
}
.select-brief p {
  font-size: 14px;
  line-height: 60px;
}
</style>
