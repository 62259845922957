<template>
  <section class="h-100">
    <div class="brief-body">
      <el-tabs :tab-position="tabPosition" class="level1" v-model="activeName">
        <el-tab-pane name="generate">
          <div slot="label">
            <i class="iconfont icon-zizhi" />
            <span>自制简报</span>
          </div>
          <select-brief v-if="generate.page === 'select'" @change="changePage" />
          <plan-brief ref="plan" v-else-if="generate.page === 'plan'" @change="changePage" @tabChange="tabChange" :templateList="templateList" />
          <material-brief ref="material" v-else-if="generate.page === 'material'" @change="changePage" @tabChange="tabChange" :templateList="templateList" />
        </el-tab-pane>
        <el-tab-pane name="list">
          <div slot="label">
            <i class="iconfont icon-liebiao" />
            <span>简报列表</span>
          </div>
          <brief-list v-if="activeName === 'list'" />
        </el-tab-pane>
        <el-tab-pane name="template">
          <div slot="label">
            <i class="iconfont icon-moban" />
            <span>简报模版</span>
          </div>
          <brief-template v-if="activeName === 'template'" :templateList="templateList" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>
<script>
import selectBrief from '@components/brief_v2/select-brief'
import briefList from '@components/brief_v2/list'
import briefTemplate from '@components/brief_v2/template'
import planBrief from '@components/brief_v2/items/plan'
import materialBrief from '@components/brief_v2/items/material'
import { lists } from '@/api/custom_briefing'
export default {
  name: 'brief',
  components: {
    selectBrief,
    briefList,
    briefTemplate,
    planBrief,
    materialBrief
  },
  data() {
    return {
      tabPosition: 'left',
      activeName: 'generate',
      generate: {
        page: 'select'
      },
      templateList: []
    }
  },
  mounted() {
    const { params } = this.$route
    if (params.hasOwnProperty('activeName')) {
      this.activeName = params.activeName
    }
    this.fetchTemplate()
  },
  watch: {
    activeName: function (name) {
      const generateIndex = this.generate.page
      if (['plan', 'material'].indexOf(generateIndex) !== -1) {
        this.$nextTick(() => {
          const stepMap = { plan: 4, material: 3 }
          if (this.$refs[generateIndex].step === stepMap[generateIndex]) {
            // 制作完成 回到首页
            this.generate.page = 'select'
          }
        })
      }
    }
  },
  methods: {
    fetchTemplate() {
      lists().then(res => {
        if (res.data.state) {
          const { data } = res.data
          this.templateList = data
        }
      }).catch(e => {
        window.console.error(e)
      })
    },
    tabChange(tabName) {
      this.$nextTick(()=> {
          this.activeName = tabName;
      })
    },
    changePage(page) {
      this.generate.page = page;
    }
  }
};
</script>
<style scoped>
.brief-body {
  padding-top: 60px;
  height: calc(100% - 60px);
}
.brief-body >>> .level1 {
  height: 100%;
}
.brief-body >>> .level1 .el-tabs__header.is-left {
  height: 100%;
  width: 180px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(65, 70, 76, 0.07);
}
.brief-body >>> .level1 .el-tabs__item {
  padding: 0;
  width: 180px;
  height: 180px;
  text-align: center;
}
.brief-body >>> .el-tabs__active-bar {
  background: none;
}
.level1 .iconfont {
  font-size: 30px;
  margin: 0 auto -25px;
  padding-top: 50px;
  display: block;
}
.level1 .el-tabs__item span {
  line-height: 30px;
  font-size: 14px;
}
.brief-body >>> .level1 .el-tabs__content,
.brief-body >>> .level1 .el-tab-pane {
  height: 100%;
}
</style>
